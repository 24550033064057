var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"ml-1 mb-1"},[_c('el-radio-group',{on:{"change":_vm.handleOrderType},model:{value:(_vm.orderType),callback:function ($$v) {_vm.orderType=$$v},expression:"orderType"}},_vm._l((_vm.orderTypeList),function(ref){
var type = ref.type;
var name = ref.name;
return _c('el-radio-button',{key:type,attrs:{"label":type}},[_vm._v(_vm._s(name)+" ")])}),1)],1),_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"姓名、订单号","queryInfo":_vm.queryInfo,"tool-list":['keyword'],"insert-select-all":['college', 'major'],"filter-keys":['tradeType', 'noTradeTypes', 'payMethod']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"时间","prop":"startDate","label-width":"60px"}},[_c('el-row',[_c('el-col',{attrs:{"span":11}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetime","size":"small","placeholder":"开始时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.queryInfo.startDate),callback:function ($$v) {_vm.$set(_vm.queryInfo, "startDate", $$v)},expression:"queryInfo.startDate"}})],1),_c('el-col',{staticClass:"line",staticStyle:{"text-align":"center"},attrs:{"span":2}},[_vm._v("-")]),_c('el-col',{attrs:{"span":11}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetime","size":"small","placeholder":"结束时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.queryInfo.endDate),callback:function ($$v) {_vm.$set(_vm.queryInfo, "endDate", $$v)},expression:"queryInfo.endDate"}})],1)],1)],1),(_vm.orderType === "dailyFee")?_c('el-form-item',{attrs:{"label":"缴费类型(日常)","prop":"tradeType","label-width":"120px"}},[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.queryInfo.dailyPayTypeId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "dailyPayTypeId", $$v)},expression:"queryInfo.dailyPayTypeId"}},_vm._l((_vm.dailyTradeTypeList),function(ref){
var name = ref.name;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":name,"value":id}})}),1)],1):_vm._e()],1),_c('div',{staticClass:"header-button mb-1"},[_c('el-tag',{staticClass:"successNum"},[_vm._v("成功支付人数："+_vm._s(_vm.countForm.successNum))]),_c('el-tag',{attrs:{"type":"success"}},[_vm._v("成功支付金额："+_vm._s(_vm.countForm.successAmountSum)+"元")]),_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.exportOrder}},[_vm._v(" 导出订单 ")]),(_vm.orderType === "dailyFee")?_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visiblePayTypeDialog = true}}},[_vm._v("缴费类型 ")]):_vm._e()],1)]},proxy:true}])},[(_vm.visibleTable)?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),(_vm.orderType !== 'customFee')?_c('el-table-column',{attrs:{"label":"缴费类型","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(['移动支付','移动支付:工行','移动支付:中行'].includes(_vm.queryInfo.payMethod) ? _vm.getTradeTypeName(row.tradeType) : (_vm.orderType === 'dailyFee' ? _vm.getDailyTradeTypeName(row.dailyPayTypeId, _vm.allDailyTradeTypeList) : '-'))+" ")]}}],null,false,1716372063)}):_vm._e(),(['dailyFee','customFee'].includes(_vm.orderType))?_c('el-table-column',{attrs:{"prop":"dailyPayTypeName","label":"缴费类型详情","width":"120","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"orderNo","label":"客户方主订单号","width":"250"}}),(_vm.queryInfo.payMethod === '移动支付')?_c('el-table-column',{attrs:{"prop":"primOrdrNo","label":"订单编号","width":"300"}}):_vm._e(),_c('el-table-column',{attrs:{"label":"订单状态","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.queryInfo.payMethod === '移动支付:中行')?[_vm._l((_vm.orderStatusList),function(item,index){return [(Number(row.ordrStcd) === Number(item.type))?_c('span',{key:index},[_vm._v(" "+_vm._s(item.name))]):_vm._e()]})]:[_vm._l((_vm.orderStatusList),function(item,index){return [(Number(row.ordrStcd) === Number(item.type))?_c('span',{key:index},[_vm._v(" "+_vm._s(item.name))]):_vm._e()]})]]}}],null,false,2166518725)}),(!(['移动支付:工行','移动支付:中行'].includes(_vm.queryInfo.payMethod)))?_c('el-table-column',{attrs:{"prop":"totalPrice","label":"订单实付金额","width":"150"}}):_vm._e(),(_vm.queryInfo.payMethod === '移动支付:中行')?_c('el-table-column',{attrs:{"prop":"amount","label":"订单实付金额","width":"150"}}):_vm._e(),(!(['移动支付:工行','移动支付:中行'].includes(_vm.queryInfo.payMethod)))?_c('el-table-column',{attrs:{"prop":"ordrGenTm","label":"订单日期","width":"200"}}):_vm._e(),_c('el-table-column',{directives:[{name:"show",rawName:"v-show",value:(['移动支付:工行','移动支付:中行'].includes(_vm.queryInfo.payMethod)),expression:"['移动支付:工行','移动支付:中行'].includes(queryInfo.payMethod)"}],attrs:{"prop":"createTime","label":"创建时间","width":"240"}}),(_vm.queryInfo.payMethod === '移动支付:工行')?_c('el-table-column',{attrs:{"prop":"amount","label":"订单金额","width":"150"}}):_vm._e(),_c('el-table-column',{attrs:{"label":"姓名","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.queryInfo.tradeType === 'dailyFee' ? row.dailyUserName : row.stuName)+" ")]}}],null,false,118551666)}),(_vm.queryInfo.noTradeTypes.length)?_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"150"}}):_vm._e(),_c('el-table-column',{attrs:{"label":"身份证","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.idNumber ? row.idNumber : row.dailyIdNumber)+" ")]}}],null,false,2564118029)}),(_vm.queryInfo.tradeType !== "dailyFee")?[_c('el-table-column',{attrs:{"prop":"collegeName","label":_vm.collegeNameLabel,"width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","width":"200"}})]:_vm._e(),(_vm.queryInfo.noTradeTypes.length || _vm.queryInfo.tradeType === 'customFee')?[_c('el-table-column',{attrs:{"prop":"className","label":"班级","width":"200"}})]:_vm._e(),(_vm.orderType !== 'customFee' && _vm.orderType !== 'dailyFee')?_c('el-table-column',{attrs:{"label":"宿舍","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryBedNo)?[_vm._v(" "+_vm._s(row.roomName ? (row.dormitoryName + row.floorNum + '层' + row.roomName + '房' + row.dormitoryBedNo + '床') : '')+" ")]:[_vm._v(" "+_vm._s(row.roomName ? (row.dormitoryName + row.floorNum + '层' + row.roomName + '房') : '')+" ")]]}}],null,false,4050275760)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","min-width":"200px"}}),_c('el-table-column',{attrs:{"prop":"remarks","label":"操作","width":"190px","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.ordrStcd && Number(row.ordrStcd) === 2)?[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadBill(row)}}},[_vm._v(" 下载电子票据 ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadBill(row, "preview")}}},[_vm._v(" 预览 ")])]:_vm._e()]}}],null,false,4017277817)})],2):_vm._e(),(_vm.visibleDialogExportDialog)?_c('selectDate',{ref:"exportDialogRef",attrs:{"visible":_vm.visibleDialogExportDialog},on:{"update:visible":function($event){_vm.visibleDialogExportDialog=$event}}}):_vm._e(),(_vm.visiblePayTypeDialog)?_c('payTypeDialog',{attrs:{"visible":_vm.visiblePayTypeDialog},on:{"update:visible":function($event){_vm.visiblePayTypeDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visiblePreviewDialog)?_c('preview-bill-pdf-dialog',{attrs:{"visible":_vm.visiblePreviewDialog,"url":_vm.previewUrl},on:{"update:visible":function($event){_vm.visiblePreviewDialog=$event},"update:url":function($event){_vm.previewUrl=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }