<template>
  <table-view :total="total" :query-info.sync="queryInfo"
              v-loading="loading"
              @reload-table="renderTable">
    <template #header>
      <div class="ml-1 mb-1">
        <el-radio-group v-model="orderType" @change="handleOrderType">
          <el-radio-button v-for="{ type, name } in orderTypeList" :key="type" :label="type">{{ name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <view-search-form ref="searchFormRef" keyword-placeholder="姓名、订单号" :queryInfo.sync="queryInfo"
                        :tool-list="['keyword']" :insert-select-all="['college', 'major']"
                        :filter-keys="['tradeType', 'noTradeTypes', 'payMethod']" @on-search="renderTable(1)">
        <el-form-item label="时间" prop="startDate" label-width="60px">
          <el-row>
            <el-col :span="11">
              <el-date-picker type="datetime" size="small" placeholder="开始时间" v-model="queryInfo.startDate"
                              style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
            <el-col class="line" style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker type="datetime" size="small" placeholder="结束时间" v-model="queryInfo.endDate"
                              style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
          </el-row>
        </el-form-item>
        <!-- 缴费类型 学费订单显示  -->
<!--     学费订单  tuitionFee迁移至学费订单模块  -->
<!--        <template v-if='orderType === "tuitionFee"'>-->
<!--          <el-form-item label="支付类型" prop="payMethod">-->
<!--            <el-select size="small" v-model="queryInfo.payMethod" @change="renderTable(1)">-->
<!--              <el-option v-for="{ name, type } in payTypeList" :label="name" :value="name" :key="type" />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item v-if="queryInfo.payMethod === '移动支付'" label="订单状态" prop="ordrStcd">-->
<!--            <el-select size="small" v-model="queryInfo.ordrStcd">-->
<!--              <el-option v-for="{ name, type } in orderStatusList" :label="name" :value="type" :key="type" />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </template>-->
        <!-- 缴费类型 日常费用显示  -->
        <el-form-item label="缴费类型(日常)" prop="tradeType" label-width="120px" v-if='orderType === "dailyFee"'>
          <el-select size="small" v-model="queryInfo.dailyPayTypeId">
            <el-option v-for="{ name, id } in dailyTradeTypeList" :label="name" :value="id" :key="id" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button mb-1">
        <el-tag class="successNum">成功支付人数：{{ countForm.successNum }}</el-tag>
        <el-tag type="success">成功支付金额：{{ countForm.successAmountSum }}元</el-tag>
        <!-- <el-button type="success" size="small" @click="showSubDialog('visibleDialogExportDialog','exportDialogRef')"
                   v-if="queryInfo.noTradeTypes.length">
          导出订单
        </el-button> -->
        <el-button type="success" size="small" @click="exportOrder">
          导出订单
        </el-button>
        <el-button size="small" v-if='orderType === "dailyFee"' @click="visiblePayTypeDialog = true">缴费类型
        </el-button>
      </div>
    </template>
    <el-table v-if="visibleTable" :data="tableData" v-loading="loading" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <!-- 学费  start -->
      <el-table-column v-if="orderType !== 'customFee'" label="缴费类型" width="140" align="center">
        <template v-slot="{ row }">
          <!-- dailyPayTypeId：日常费用的缴费类型 -->
          {{['移动支付','移动支付:工行','移动支付:中行'].includes(queryInfo.payMethod) ? getTradeTypeName(row.tradeType) : (orderType === 'dailyFee' ?
          getDailyTradeTypeName(row.dailyPayTypeId, allDailyTradeTypeList) : '-')
          }}
        </template>
      </el-table-column>
      <!-- 学费  end -->
      <el-table-column v-if="['dailyFee','customFee'].includes(orderType)" prop="dailyPayTypeName"
                       label="缴费类型详情"
                       width="120" align="center" />
      <el-table-column prop="orderNo" label="客户方主订单号" width="250" />
      <!--      <template v-if='orderType === "tuitionFee"||orderType === "chargingFee"'>-->
      <el-table-column v-if="queryInfo.payMethod === '移动支付'" prop="primOrdrNo" label="订单编号" width="300" />
      <!--      </template>-->
      <el-table-column label="订单状态" width="120">
        <template v-slot="{ row }">
          <template v-if="queryInfo.payMethod === '移动支付:中行'">
            <template v-for="(item, index) in orderStatusList">
            <span :key="index" v-if="Number(row.ordrStcd) === Number(item.type)">
              {{ item.name }}</span>
            </template>
          </template>
          <template v-else>
            <template v-for="(item, index) in orderStatusList">
            <span :key="index" v-if="Number(row.ordrStcd) === Number(item.type)">
              {{ item.name }}</span>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column v-if="!(['移动支付:工行','移动支付:中行'].includes(queryInfo.payMethod))"
                       prop="totalPrice"
                       label="订单实付金额" width="150" />
      <el-table-column v-if="queryInfo.payMethod === '移动支付:中行'" prop="amount" label="订单实付金额" width="150" />
      <el-table-column v-if="!(['移动支付:工行','移动支付:中行'].includes(queryInfo.payMethod))"
                       prop="ordrGenTm"
                       label="订单日期" width="200" />
      <el-table-column v-show="['移动支付:工行','移动支付:中行'].includes(queryInfo.payMethod)"
                       prop="createTime"
                       label="创建时间" width="240" />
      <el-table-column v-if="queryInfo.payMethod === '移动支付:工行'" prop="amount" label="订单金额" width="150" />
      <el-table-column label="姓名" width="140">
        <template v-slot="{ row }">
          {{ queryInfo.tradeType === 'dailyFee' ? row.dailyUserName : row.stuName }}
        </template>
      </el-table-column>
      <!--  queryInfo.noTradeTypes.length ==学费订单    -->
      <el-table-column v-if="queryInfo.noTradeTypes.length" prop="examineNo" label="录取编号" width="150" />
      <el-table-column label="身份证" width="200">
        <template v-slot="{ row }">
          {{ row.idNumber ? row.idNumber : row.dailyIdNumber }}
        </template>
      </el-table-column>
      <template v-if='queryInfo.tradeType !== "dailyFee"'>
        <el-table-column prop="collegeName" :label="collegeNameLabel" width="150" />
        <el-table-column prop="majorName" label="专业" width="200" />
      </template>
      <template v-if="queryInfo.noTradeTypes.length || queryInfo.tradeType === 'customFee'">
        <el-table-column prop="className" label="班级" width="200" />
      </template>
      <el-table-column label="宿舍" width="300" v-if="orderType !== 'customFee' && orderType !== 'dailyFee'">
        <template v-slot="{ row }">
          <template v-if="row.dormitoryBedNo">
            {{ row.roomName ? (row.dormitoryName + row.floorNum + '层' + row.roomName + '房' + row.dormitoryBedNo + '床') :
            ''
            }}
          </template>
          <template v-else>
            {{ row.roomName ? (row.dormitoryName + row.floorNum + '层' + row.roomName + '房') : ''
            }}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="200px" />
      <el-table-column prop="remarks" label="操作" width="190px" fixed="right" align="center">
        <template v-slot="{ row }">
          <!--  自定义缴费批次无法下载单据,其他的  row.ordrStcd=2 支付成功时才能下载和预览  orderType!=='customFee'&&-->
          <template v-if="row.ordrStcd && Number(row.ordrStcd) === 2">
            <el-button type="text" @click="downloadBill(row)">
              下载电子票据
            </el-button>
            <el-button type="text" @click='downloadBill(row, "preview")'>
              预览
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <selectDate ref="exportDialogRef" :visible.sync="visibleDialogExportDialog" v-if="visibleDialogExportDialog" />
    <!--  编辑、新增 自定义缴费类型 dialog -->
    <payTypeDialog :visible.sync="visiblePayTypeDialog" v-if="visiblePayTypeDialog" @on-close="renderTable" />
    <!--  预览 dialog  -->
    <preview-bill-pdf-dialog :visible.sync="visiblePreviewDialog" v-if="visiblePreviewDialog" :url.sync="previewUrl" />
  </table-view>
</template>

<script>
import {
  getPayOrderListApi,
  getTuitionOrderOrderListApi,
  getBocOrderOrderListApi,
  getIcbcOrderOrderListApi
} from '@/api/finance-manage/studentOrder'
import selectDate from '../dialog/selectDate.vue'
import { orderStatusList, payTypeList, orderTypeList } from '@/views/pages/financeManage/searchOrder/component/options'
import tableView from '@/vue/mixins/table-view'
import payTypeDialog from '@/views/pages/financeManage/searchOrder/component/payTypeDialog.vue'
import {
  generateBillPdfApi,
  generateOtherBillPdfApi,
  listDailyPayTypeApi,
  listAllDailyPayTypeApi, generateDailyPayBillPdfApi
} from '@/api/finance-manage/pay-type-api'
import PreviewBillPdfDialog from '@/views/pages/financeManage/searchOrder/component/previewBillPdfDialog.vue'

export default {
  name: 'searchOrder',
  mixins: [tableView],
  components: {
    PreviewBillPdfDialog,
    payTypeDialog,
    selectDate
  },
  data() {
    return {
      visibleTable: true, // 切换条件时重新加载table，解决数据不刷新的问题
      visibleDialogExportDialog: false,
      payMethod: '移动支付',
      tradeTypeList: [], // 学费 缴费类型
      dailyTradeTypeList: [], // 日常费用 缴费类型
      allDailyTradeTypeList: [], // 日常费用 缴费类型(包括已删除)
      visiblePayTypeDialog: false,
      orderStatusList,
      payTypeList,
      orderTypeList,
      orderType: 'chargingFee', // 订单类型 前端过滤用
      queryInfo: {
        campusId: null,
        collegeId: null,
        majorId: null,
        classesId: null,
        startDate: null,
        endDate: null,
        noTradeTypes: [], // =获取所有学费订单,用于排除电费跟日常费用的数据
        // noTradeTypes: ['dailyFee', 'chargingFee', 'customFee', 'customYuanWeiFee'], // =获取所有学费订单,用于排除电费跟日常费用的数据
        tradeType: 'chargingFee', // 电费 or 日常费用时 需要此字段
        payMethod: '移动支付',
        ordrStcd: null,
        dailyPayTypeId: null // 日常费用 缴费类型
      },
      countForm: {
        successNum: 0,
        failNum: 0,
        successAmountSum: 0
      },
      tableData: [],
      previewUrl: '',
      visiblePreviewDialog: false,
      timer: null
    }
  },
  computed: {
    compHeader() {
      // 学费订单tuitionFee已经迁移至学费订单模块
      let _result = []
      switch (this.orderType) {
        case 'tuitionFee':
          _result = ['college', 'major', 'class', 'keyword']
          break
        default:
          _result = ['keyword']
      }
      return _result
    },
    collegeNameLabel() {
      return (this.queryInfo.tradeType === 'chargingFee' || this.queryInfo.tradeType === 'customFee') ? '院系/部门' : '院系'
    }
  },
  async mounted() {
    await Promise.all([
      this.getDailyTradeTypeList(),
      this.getAllDailyTradeTypeList(),
      this.$http.getParamListByKey('tradeType', this, 'tradeTypeList')
    ])
  },
  methods: {
    // 导出订单
    async exportOrder() {
      if (this.queryInfo.noTradeTypes.length) {
        this.showSubDialog('visibleDialogExportDialog', 'exportDialogRef')
      } else {
        const formData = {
          startDate: this.queryInfo.startDate,
          endDate: this.queryInfo.endDate,
          keyword: this.queryInfo.keyword
        }
        switch (this.queryInfo.tradeType) {
          // 电费
          case 'chargingFee':
            formData.payMethod = '电费'
            await this.$http.exportExcelHttp.exportPayOrder(formData, '电费支付订单')
            this.$message.success('导出成功，已开始下载文档！')
            break
          // 日常费用
          case 'dailyFee':
            formData.payMethod = '日常缴费'
            formData.dailyPayTypeId = this.queryInfo.dailyPayTypeId
            await this.$http.exportExcelHttp.exportPayOrder(formData, '日常费用支付订单')
            this.$message.success('导出成功，已开始下载文档！')
            break
          // 自定义缴费
          case 'customFee':
            formData.payMethod = '自定义缴费'
            await this.$http.exportExcelHttp.exportPayOrder(formData, '自定义费用支付订单')
            this.$message.success('导出成功，已开始下载文档！')
            break

          default:
            break
        }
      }
    },
    async handleOrderList(apiMethod) {
      try {
        this.loading = true
        const res = await apiMethod(this.queryInfo)
        const {
          list,
          total,
          successNum,
          successAmountSum
        } = res.data
        this.tableData = list
        this.total = total
        this.countForm.successNum = successNum
        this.countForm.successAmountSum = successAmountSum
        this.payMethod = this.queryInfo.payMethod
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // 获取订单列表
    async renderTable(pageNum) {
      if (pageNum) this.queryInfo.pageNum = pageNum
      this.visibleTable = false
      switch (this.queryInfo.payMethod) {
        case '移动支付':
          await this.handleOrderList(getPayOrderListApi)
          break
        case '移动支付:中行':
          await this.handleOrderList(getBocOrderOrderListApi)
          break
        case '移动支付:工行':
          await this.handleOrderList(getIcbcOrderOrderListApi)
          break
        default:
          await this.handleOrderList(getTuitionOrderOrderListApi)
      }
      this.visibleTable = true
    },
    // 日常费用 的缴费类型(所有 包括已删除)
    async getAllDailyTradeTypeList() {
      try {
        const res = await listAllDailyPayTypeApi()
        this.allDailyTradeTypeList = res.data
      } catch (e) {
      }
    },
    // 日常费用 的缴费类型  type=1 扫码
    async getDailyTradeTypeList() {
      try {
        const res = await listDailyPayTypeApi('1')
        this.dailyTradeTypeList = res.data
      } catch (e) {
      }
    },
    // 获取缴费类型
    getTradeTypeName(type) {
      if (!type) return
      for (let i = 0; i < this.tradeTypeList.length; i++) {
        const {
          remarks,
          paramName
        } = this.tradeTypeList[i]
        if (paramName === type) {
          return remarks
        }
      }
    },
    // 获取日常费用 缴费类型名
    getDailyTradeTypeName(typeId, arr) {
      if (!typeId) return
      for (let i = 0; i < arr.length; i++) {
        const {
          name,
          id
        } = arr[i]
        if (typeId === id) {
          return name
        }
      }
    },
    async handleOrderType(val) {
      await this.clear()

      this.queryInfo.tradeType = val
      this.queryInfo.noTradeTypes = []
      // 等于 日常费用 dailyFee 时 用 noTradeType  其他类型 用tradeType
      // switch (val) {
      //   case 'tuitionFee':
      //     this.queryInfo.tradeType = null
      //     this.queryInfo.noTradeTypes = ['dailyFee', 'chargingFee']
      //     break
      //   default:
      //     this.queryInfo.tradeType = val
      //     this.queryInfo.noTradeTypes = []
      // }
      // 切换页签后,需要重新保存一下初始queryInfo,用于重置操作
      this.$refs.searchFormRef.setInitQuery()
      await this.renderTable()
    },
    /* 下载 电子票据 (逻辑繁琐,修改请谨慎)
    * 学费 三种移动支付类型的 生成pdf+下载api是一个接口组
    * 学费 除了三种移动支付类型外  生成pdf+下载api 是第二个接口组
    *  电费 生成pdf+下载的api 调用 学费中移动支付(建行) 的接口组
    *  日常费用 生成pdf+下载的api 生成pdf+下载api 是第三个接口组
    */
    async downloadBill(row, type = '') {
      this.loading = true
      // 生成pdf的所在路径, 学费-其他类型支付时
      let _pdfLink = '/stu/stuInfo/downloadPayBillFile/'
      // 移动支付时
      const _isMobilePay = ['移动支付', '移动支付:中行', '移动支付:工行'].includes(this.queryInfo.payMethod)
      try {
        let res
        // 日常费用账单 生成
        if (this.queryInfo.tradeType && this.queryInfo.tradeType === 'dailyFee') {
          const _dailyQuery = {
            id: row.id,
            idNumber: row.dailyIdNumber
          }
          res = await generateDailyPayBillPdfApi(_dailyQuery)
          _pdfLink = '/pay/payOrder/downloadDailyPayBillFile/'
          // return
        } else {
          // 学费 移动支付 类型
          if (_isMobilePay) {
            // 电费都是建行 ccb 学费三个银行都有
            const _query = {
              bankType: 'ccb',
              id: row.id
            }
            // 学费 移动支付
            // if (this.orderType === 'tuitionFee') {
            //   switch (this.queryInfo.payMethod) {
            //     case '移动支付:中行':
            //       _query.bankType = 'boc'
            //       break
            //     case '移动支付:工行':
            //       _query.bankType = 'icbc'
            //       break
            //     default:
            //       _query.bankType = 'ccb'
            //   }
            // }
            _pdfLink = '/pay/app/payTotalOrder/downloadPayBillFile/'
            res = await generateBillPdfApi(_query)
          } else {
            // 学费 其他类型支付 + 电费
            res = await generateOtherBillPdfApi(row.id)
          }
        }

        const url = `${this.$baseUrl}${_pdfLink}${res.data}`
        if (type === 'preview') {
          this.previewUrl = url
          this.visiblePreviewDialog = true
        } else {
          const fileName = '广东南方职业学院教育收费收据'
          // 创建一个a标签，设置它的Download属性和链接
          const a = document.createElement('a')
          // 使其隐藏
          a.style.display = 'none'
          a.download = fileName
          a.href = url
          // 将a标签添加到body元素中
          document.body.appendChild(a)
          // 模拟用户点击a标签来启动下载
          a.click()
          // 删除a标签
          document.body.removeChild(a)
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.successNum {
  margin-right: 20px;
}
</style>
